export const state = () => ({
  isEmbeddedBlocksHidden: false,
});

export const mutations = {
  SET_IS_EMBEDDED_BLOCKS_HIDDEN(state, payload) {
    state.isEmbeddedBlocksHidden = payload;
  },
};

export const actions = {};
