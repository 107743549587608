//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EButton from '@/components/d0822/fields/button.vue';

export default {
  components: {
    EButton,
  },
  data() {
    return {
      bounce: false,
    };
  },
  computed: {
    display() {
      return this.$store.state.modalNotify.display;
    },
    closing() {
      return this.$store.state.modalNotify.closing;
    },
    opening() {
      return this.$store.state.modalNotify.opening;
    },
    data() {
      return this.$store.state.modalNotify.data;
    },
    buzy() {
      return this.$store.state.modalNotify.buzy;
    },
    persistent() {
      return this.$store.state.modalNotify.data?.persistent;
    },
  },
  watch: {
    display(payload) {
      try {
        if (process.server) return;
        const html = document.documentElement;

        if (payload) {
          html.style.scrollbarGutter = 'stable';
          html.style.overflow = 'hidden';
        } else {
          html.style.scrollbarGutter = 'initial';
          html.style.overflow = 'auto';
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  methods: {
    closeAndCallback() {
      this.$store.dispatch('modalNotify/CLOSE');
    },
    close() {
      if (this.persistent && this.bounce) return;

      this.bounce = true;
      setTimeout(() => (this.bounce = false), 250);

      if (this.persistent) return;

      this.$store.dispatch('modalNotify/CLOSE_FORCE');
    },
  },
};
