export default function ({ error, store, req, redirect }) {
  if (!store.state.sale.sale) {
    return error({ statusCode: 404, message: 'This page could not be found' });
  }
  if (
    new Date(store.state.sale.sale.endDate).getTime() < new Date().getTime()
  ) {
    const queryObject = store.$router.history.current.query;
    const querySearchParams = new URLSearchParams(queryObject);
    const queryString = querySearchParams.toString();

    // Редирект на страницу курсов по-умолчанию
    let path = queryString ? `/cursos?${queryString}` : '/cursos';

    // Если в CMS заполнено поле sale.redirect
    // То после завершения распродажи перенаправление
    // будет идти на адрес из этого поля, а не на страницу курсов
    let redirectFilledInCMS = store?.state?.sale?.sale?.redirect;
    if (redirectFilledInCMS) {
      // Получить get параметры адреса из поля редиректа.
      // на случай, если мы редиректим на адрес с get параметрами
      // и хотим сохранить и их, и параметры из текущего адреса
      try {
        // Обработать адрес без http(s)
        if (!redirectFilledInCMS.includes('http')) {
          const host = req?.headers?.host;

          // Протокол всегда https, кроме случаев, когда площадка
          // запущена локально (localhost)
          let protocol = 'https://';
          if (host.includes('localhost')) protocol = 'http://';

          // Добавить слэш в начале, если его нет
          if (redirectFilledInCMS[0] !== '/') {
            redirectFilledInCMS = `/${redirectFilledInCMS}`;
          }

          // Собрать итоговый адрес
          redirectFilledInCMS = `${protocol}${host}${redirectFilledInCMS}`;
        }

        const redirectUrl = new URL(redirectFilledInCMS);
        const redirectQuery = redirectUrl.search;

        // Объединить параметры из текущего адреса и адреса из CMS
        let mergedParams = mergeQuery(queryString, redirectQuery);

        let redirected = +querySearchParams.get('redirected') + 1 || 0;
        mergedParams = mergeQuery(mergedParams, `?redirected=${redirected}`);

        if (redirected > 2) {
          path = `/cursos`;
          return redirect(path);
        }

        // Заменить в адресе GET параметры
        redirectUrl.search = mergedParams;
        path = redirectUrl.toString();
      } catch (e) {
        console.error(e);
        // Редирект произойдёт на страницу курсов
      }
    }

    return redirect(path);
  }
}

/** Объединить параметры. На входе строки. Приоритет у b */
function mergeQuery(a, b) {
  const queryA = new URLSearchParams(a);
  const queryB = new URLSearchParams(b);

  const queryAObj = Object.fromEntries(queryA);
  const queryBObj = Object.fromEntries(queryB);

  const mergedObj = { ...queryAObj, ...queryBObj };

  let mergedArr = [];
  for (const key in mergedObj) {
    const param = mergedObj[key];
    mergedArr.push(`${key}=${param}`);
  }

  if (!mergedArr.length) return '';

  const mergedQuery = `?${mergedArr.join('&')}`;
  return mergedQuery;
}
