//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalNotify from '@/components/ModalNotify.vue';
import SaleBannerV2 from '../components/saleBannerV2';
import isMobile from '../mixins/isMobile.mixin';
import setDefaultCookie from '../mixins/setDefaultCookie.mixin';
import PortalDialogTarget from '@/components/portalDialogTarget.vue';

export default {
  components: {
    SaleBannerV2,
    PortalDialogTarget,
    ModalNotify,
  },
  mixins: [isMobile, setDefaultCookie],
  data() {
    return {
      layout: undefined,
      observer: undefined,
      saleBannerExcludeRoutes: [
        `webinars-direction___${this.$app.config.current.localeName}`,
        `webinarDetailed___${this.$app.config.current.localeName}`,
        `sale___${this.$app.config.current.localeName}`,
        `sale-sale___${this.$app.config.current.localeName}`,
        `sale-thankyou___${this.$app.config.current.localeName}`,
        `webinarSale___${this.$app.config.current.localeName}`,
        `webinarSaleNew___${this.$app.config.current.localeName}`,
        `webinars-slug___${this.$app.config.current.localeName}`,
        `payment-generate___${this.$app.config.current.localeName}`,
        `payment-fail___${this.$app.config.current.localeName}`,
        `payment-success___${this.$app.config.current.localeName}`,
      ],
      saleBannerIsExternal:
        this.$app.config.current.saleBanner.eternalV2 ||
        this.$app.config.current.saleBanner.eternal,
      saleBannerTopPositionRoutes: [
        `webinars-slug___${this.$app.config.current.localeName}`,
      ],
      isShowSaleBanner: false,
    };
  },
  computed: {
    isEmbeddedBlocksHidden() {
      return this.$store.state.ui.isEmbeddedBlocksHidden;
    },
  },
  watch: {
    $route: {
      handler() {
        if (process.client) {
          window.dispatchEvent(new Event('recalculateCookieConsent'));
        }

        if (!this.saleBannerExcludeRoutes.includes(this.$route.name)) {
          this.isShowSaleBanner = true;
        } else this.isShowSaleBanner = false;
      },
      immediate: true,
    },
  },
};
