import * as Sentry from '@sentry/vue';

export function sentryReplayForAuthorizedUsers(user) {
  if (!user) {
    return;
  }

  Sentry.setUser({ email: user.email });

  if (Math.random() > 0.1) {
    return;
  }

  const replay = Sentry.getClient()?.getIntegrationByName?.('Replay');

  if (!replay) {
    return;
  }

  replay.getReplayId() ? replay.flush() : replay.startBuffering();
}
