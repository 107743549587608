var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.loadingPage > 0)?_c('div',{staticClass:"spinnerMainPage"},[_c('VProgressCircular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_vm._v(" "),_c('PortalTarget',{attrs:{"name":"sale-floating-basket"}}),_vm._v(" "),_c('PortalTarget',{attrs:{"name":"sale-panel"}}),_vm._v(" "),_c('PortalDialogTarget'),_vm._v(" "),_c('ModalNotify'),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"autoscale"},[_c('Nuxt')],1),_vm._v(" "),(
      _vm.isShowSaleBanner && !_vm.$app.ui.webinars.isDisplayPromoBlockRegistration
    )?_c('SaleBannerV2',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmbeddedBlocksHidden),expression:"!isEmbeddedBlocksHidden"}],attrs:{"classes":{
      'sale-block_position-top-mobile': _vm.saleBannerTopPositionRoutes.includes(
        _vm.$route.name
      ),
      'sale-block_z-index-6': _vm.saleBannerTopPositionRoutes.includes(
        _vm.$route.name
      ),
      'sale-block_landing-position': _vm.$store.state.isLandingPage,
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }