import sortBy from 'lodash/sortBy';
import getNomenclatureImage from '@/pages/sale/new-design/utils/getNomenclatureImage';

export const state = () => {
  const detailProductDialogDefault = {
    loading: true,
    product: null,
    productType: '',
    content: {
      months: 0,
      title: '',
      description: '',
      program: [],
      skills: [],
      img: '',
      trailerUrl: '',
      courseProgram: null,
      teachers: [],
    },
  };

  return {
    // Redesigned Filter
    professions: [],
    professionsVisible: true,
    professionsBuzy: false,
    professionsDelayedUpdate: false,
    professionsTotal: 0,
    professionsMore: true,
    professionsTitle: '',

    courses: [],
    coursesVisible: true,
    coursesBuzy: false,
    coursesDelayedUpdate: false,
    coursesTotal: 0,
    coursesMore: true,
    coursesTitle: '',
    // Redesigned Filter end

    sale: null,

    coursesCanLoadMore: false,
    professionsCanLoadMore: false,
    directions: [],

    isShowedMobileFilter: false,
    isDisplayDetailProductDialog: false,
    detailProductDialogDefault,
    detailProductDialog: detailProductDialogDefault,
    basketDialog: false,
    choiceDialog: false,

    topSaleCoursesAndProfessions: [],
    isLoadingTopSaleCoursesAndProfessions: false,

    modal: {
      isVisible: false,
      title: '',
      description: '',
      buttonText: '',
    },
  };
};

export const getters = {
  twoForOne: (state) => {
    return state.sale?.modes?.twoForOne;
  },
  twoForOneWithoutProfessions: (state) => {
    return (
      state.sale?.modes?.twoForOne &&
      state.sale?.modes?.twoForOneWithoutProfessions
    );
  },
  isSaleWithoutPrice: (state) => {
    if (state.sale) {
      return state.sale.options.displayMode === 'shoppingDisabled';
    }
  },
  isCompanyCatalog: (state) => {
    if (state.sale) {
      return state.sale.options.displayMode === 'companyCatalog';
    }
  },
  processedTopSaleCoursesAndProfessions:
    (state) =>
    ({ vm, basket }) => {
      const topSaleCoursesAndProfessions = state.topSaleCoursesAndProfessions;
      if (!topSaleCoursesAndProfessions) return [];

      return topSaleCoursesAndProfessions.map((profession) => {
        const item = { ...profession };
        item.price = getProductPrice({
          vm,
          product: item,
          defaultSaleType: state.sale.defaultSaleType,
        });
        item.additionalNomenclaturesString =
          getProductAdditionalNomenclaturesString({ vm, product: item });

        if (!item.image) {
          item.image = getNomenclatureImage({ vm, product: item });
        }

        if (basket.find((basketItem) => basketItem.id === item.id)) {
          item.inBasket = true;
        } else {
          item.inBasket = false;
        }

        return item;
      });
    },
  processedProfessions:
    (state) =>
    ({ vm, basket }) => {
      const professions = state.professions;
      if (!professions) return [];

      return professions.map((profession) => {
        const item = { ...profession };
        item.price = getProductPrice({
          vm,
          product: item,
          defaultSaleType: state.sale.defaultSaleType,
        });
        item.additionalNomenclaturesString =
          getProductAdditionalNomenclaturesString({ vm, product: item });

        if (!item.image) {
          item.image = getNomenclatureImage({ vm, product: item });
        }

        if (basket.find((basketItem) => basketItem.id === item.id)) {
          item.inBasket = true;
        } else {
          item.inBasket = false;
        }

        return item;
      });
    },
  processedCourses:
    (state) =>
    ({ vm, basket }) => {
      const courses = state.courses;
      if (!courses) return [];

      return courses.map((course) => {
        const item = { ...course };
        item.price = getProductPrice({
          vm,
          product: item,
          defaultSaleType: state.sale.defaultSaleType,
        });
        item.additionalNomenclaturesString =
          getProductAdditionalNomenclaturesString({ vm, product: item });

        if (!item.image) {
          item.image = getNomenclatureImage({ vm, product: item });
        }

        if (basket.find((basketItem) => basketItem.id === item.id)) {
          item.inBasket = true;
        } else {
          item.inBasket = false;
        }

        return item;
      });
    },
  // Поведение корзины на странице распродажи
  // Ключ - slug, value - опция
  // 1 - default. дефолт
  // 2 - persistent. дефолт + при добавлении в корзину, popup корзины сразу же будет появляться
  // 3 - withoutBasket. как таковой корзины нет, при добавлении в корзину будет появляться popup корзины,
  // но в popup`e будут только форма, списка продуктов не будет. После закрытия popup`а,
  // товар будет удаляться из корзины. Плавающая иконка корзины пропадает
  basketType: (state) => {
    const slug = state.sale?.slug;
    if (!slug) return 1;

    const type = state.sale.basketType;
    if (!type) return 1;

    return type;
  },
  isSaleReferral: (state) => (vueComponent) => {
    return vueComponent.$app.config.current?.saleReferralSlug.includes(
      state.sale?.slug,
    );
  },
  // Страница распродажи с колесом фортуны
  isFortuneWheel: (state) => (vm) => {
    return (vm.$app.config.current.fortuneWheelSlug || []).includes(
      state.sale?.slug,
    );
  },
  computedTopSaleCoursesAndProfessions: (state) => {
    return state.topSaleCoursesAndProfessions;
  },
  computedIsLoadingTopSaleCoursesAndProfessions(state) {
    return state.isLoadingTopSaleCoursesAndProfessions;
  },
};

export const mutations = {
  // Redesigned Filter
  setCourses(state, courses) {
    state.courses = courses;
  },
  setProfessions(state, professions) {
    state.professions = professions;
  },

  setCoursesVisible(state, payload) {
    state.coursesVisible = payload;
  },
  setCoursesBuzy(state, payload) {
    state.coursesBuzy = payload;
  },
  setCoursesDelayedUpdate(state, payload) {
    state.coursesDelayedUpdate = payload;
  },
  setCoursesTotal(state, total) {
    state.coursesTotal = total;
  },
  setCoursesMore(state, more) {
    state.coursesMore = more;
  },
  setCoursesTitle(state, title) {
    state.coursesTitle = title;
  },

  setProfessionsVisible(state, payload) {
    state.professionsVisible = payload;
  },
  setProfessionsBuzy(state, payload) {
    state.professionsBuzy = payload;
  },
  setProfessionsDelayedUpdate(state, payload) {
    state.professionsDelayedUpdate = payload;
  },
  setProfessionsTotal(state, total) {
    state.professionsTotal = total;
  },
  setProfessionsMore(state, more) {
    state.professionsMore = more;
  },
  setProfessionsTitle(state, title) {
    state.professionsTitle = title;
  },
  // Redesigned Filter end

  setSale(state, sale) {
    state.sale = sale;
  },
  setTopSaleCoursesAndProfessions(state, payload) {
    state.topSaleCoursesAndProfessions = payload;
  },
  setIsLoadingTopSaleCoursesAndProfessions(state, payload) {
    state.isLoadingTopSaleCoursesAndProfessions = payload;
  },
  setProfessionsCanLoadMore(state, professionsCanLoadMore) {
    state.professionsCanLoadMore = professionsCanLoadMore;
  },
  setCoursesCanLoadMore(state, coursesCanLoadMore) {
    state.coursesCanLoadMore = coursesCanLoadMore;
  },
  setDirections(state, directions) {
    state.directions = directions;
  },
  setIsShowedMobileFilter(state, isShowedMobileFilter) {
    state.isShowedMobileFilter = isShowedMobileFilter;
  },
  setIsDisplayDetailProductDialog(state, isDisplayDetailProductDialog) {
    // TODO: когда появятся общая компонента для диалоговых окон переделать, на общий метод в сторе
    lockScroll(isDisplayDetailProductDialog);
    state.isDisplayDetailProductDialog = isDisplayDetailProductDialog;
  },
  setDetailProductDialog(state, detailProductDialog) {
    state.detailProductDialog = detailProductDialog;
  },
  setBasketDialog(state, basketDialog) {
    // TODO: когда появятся общая компонента для диалоговых окон переделать, на общий метод в сторе
    lockScroll(basketDialog);
    state.basketDialog = basketDialog;
  },
  setChoiceDialog(state, payload) {
    // TODO: когда появятся общая компонента для диалоговых окон переделать, на общий метод в сторе
    lockScroll(payload);
    state.choiceDialog = payload;
  },
  setModal(state, payload) {
    state.modal = payload;
  },
};

export const actions = {
  async GET_TOGGLE_FILTER_PARAMS({ state }, { vm, messages }) {
    const directions = state.directions.map((i) =>
      createParam({
        ...i,
        vm,
        messages,
        paramType: 'courses',
        label: i.name || getI18nString({ vm, messages, code: 'saleNew.todos' }),
        value: i.id || 0,
      }),
    );

    const typeItems = [
      {
        code: 'saleNew.type.course',
        value: '0',
        slug: 'course',
        icons: {
          default: require('~/assets/svg/ic-course.svg'),
          hover: require('~/assets/svg/ic-course-hover.svg'),
          active: require('~/assets/svg/ic-course-active.svg'),
        },
      },
      {
        code: 'saleNew.type.profession',
        value: '1',
        slug: 'profession',
        icons: {
          default: require('~/assets/svg/ic-profession.svg'),
          hover: require('~/assets/svg/ic-profession-hover.svg'),
          active: require('~/assets/svg/ic-profession-active.svg'),
        },
      },
    ];
    const types = typeItems.map((i) =>
      createParam({
        ...i,
        vm,
        messages,
        paramType: 'types',
      }),
    );

    const additionalItems = [
      {
        code: 'saleNew.additional.jobGuarantee',
        name: 'jobGuarantee',
        icons: {
          default: require('~/assets/svg/ic-briefcase.svg'),
          hover: require('~/assets/svg/ic-briefcase-hover.svg'),
          active: require('~/assets/svg/ic-briefcase-active.svg'),
        },
      },
      // {
      //   code: 'saleNew.additional.bundle',
      //   name: 'bundle',
      //   icons: {
      //     default: require('~/assets/svg/ic-gift.svg'),
      //     hover: require('~/assets/svg/ic-gift-hover.svg'),
      //     active: require('~/assets/svg/ic-gift-active.svg'),
      //   },
      // },
    ];
    const additional = additionalItems.map((i) =>
      createParam({
        ...i,
        vm,
        messages,
        paramType: i.name,
        value: true,
      }),
    );

    const allParams = [
      directions[0],
      ...types,
      ...directions.slice(1),
      ...additional,
    ];
    return allParams;
  },
  SHOW_MODAL({ commit }, { title, description, buttonText }) {
    // TODO: когда появятся общая компонента для диалоговых окон переделать, на общий метод в сторе
    lockScroll(true);

    commit('setModal', {
      isVisible: true,
      title,
      description,
      buttonText,
    });
  },
  CLOSE_MODAL({ commit }) {
    // TODO: когда появятся общая компонента для диалоговых окон переделать, на общий метод в сторе
    lockScroll(false);

    commit('setModal', {
      isVisible: false,
      title: '',
      description: '',
      buttonText: '',
    });
  },
  async SHOW_DETAIL_PRODUCT_DIALOG(
    { state, commit, dispatch },
    { product, productType },
  ) {
    this.$gtm.push({
      event: 'autoEvent',
      eventCategory: 'ds_click',
      eventAction: 'course_description',
      eventLabel: product.shortName || product.name,
    });

    commit('setDetailProductDialog', {
      ...state.detailProductDialogDefault,
      loading: true,
    });

    commit('setIsDisplayDetailProductDialog', true);

    const detail = await dispatch('GET_PRODUCT_DETAIL', { id: product.id });

    if (detail) {
      commit('setDetailProductDialog', {
        ...state.detailProductDialogDefault,
        product,
        productType,
        content: {
          ...state.detailProductDialogDefault.content,
          months: product.duration,
          title: product.shortName || product.name,
          description: detail.description,
          program: detail.detailProgram,
          trailerUrl: detail.detailVideo,
          teachers: sortBy(detail.teachers, 'num'),
          img: detail.detailImage,
          courseProgram: detail.courseProgramFile,
          skills: Array.isArray(detail.detailBenefits)
            ? detail.detailBenefits.map((benefit) => ({
                ...benefit,
                description: benefit.text,
              }))
            : [],
        },
      });
    } else {
      commit('setIsDisplayDetailProductDialog', false);
    }

    commit('setDetailProductDialog', {
      ...state.detailProductDialog,
      loading: false,
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async GET_PRODUCT_DETAIL({}, { id }) {
    let response;
    try {
      response = await this.$axios({
        method: 'post',
        url: `${this.$config.CMS_REST_API}/public/get-nomenclature-detail/`,
        data: {
          id,
        },
      });
    } catch (e) {
      console.error(e);
    }

    return response?.data?.data;
  },
  async fetchTopSaleCoursesAndProfessions({ commit, state }, { vm }) {
    const url = '/public/v2/showcase/';
    const saleId = state.sale.id;
    try {
      commit('setIsLoadingTopSaleCoursesAndProfessions', true);
      let response = await this.$axios.get(`${vm.$config.CMS_REST_API}${url}`, {
        params: {
          types: [0, 1],
          limit: 6,
          topSale: true,
          saleId,
        },
      });
      const payloadData = response.data.data.data;
      commit('setTopSaleCoursesAndProfessions', payloadData);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setIsLoadingTopSaleCoursesAndProfessions', false);
    }
  },
};

function createParam({
  vm,
  messages,
  code,
  value,
  slug,
  icon,
  icons,
  paramType,
  label,
}) {
  return {
    label:
      label ||
      getI18nString({
        vm,
        messages,
        code,
      }),
    value: `${paramType}.${value}`,
    slug,
    paramType,
    icon,
    icons,
  };
}

function getI18nString({ vm, messages, code }) {
  if (vm) return vm.$t(code);
  if (messages) return messages[code];
  return code;
}

function getProductPrice({ vm, product, defaultSaleType }) {
  const defaultSalePrice = defaultSaleType;
  const basePrice = Number(product?.basePrice || 0);
  let price = {
    priceInstallmentPlan: 12,
    basePrice,
    basePriceFormatted: vm.$n(basePrice, 'currency'),
  };
  let productPrice;
  let priceType;

  if (product.salePrice.isDefaultPrice) {
    // если цена не выбрана
    const source = product.sources.find(
      (source) => source.pageType === defaultSalePrice,
    );
    if (source) {
      productPrice = product.nomenclaturePrices.find(
        (productPrice) => productPrice.priceTypeId === source.priceTypeId,
      );
      if (productPrice) {
        price.price = productPrice?.price;
        priceType = product.priceTypes.find(
          (priceType) => priceType.id === productPrice.priceTypeId,
        );
        price.percent = priceType.percent;
        price.priceInstallmentPlanPrice = vm.$app.getCurrency(
          price.price / price.priceInstallmentPlan,
        );
      }
    }
  } else if (product.salePrice.withNomenclaturePrice) {
    // если цена выбрана
    productPrice = product.nomenclaturePrices[0];
    if (productPrice) {
      price.price = productPrice.price;
      priceType = product.priceTypes.find(
        (priceType) => priceType.id === productPrice.priceTypeId,
      );
      price.percent = priceType.percent;
      price.priceInstallmentPlanPrice = vm.$app.getCurrency(
        price.price / price.priceInstallmentPlan,
      );
    }
  }

  return price;
}

function getProductAdditionalNomenclaturesString({ vm, product }) {
  let additionalNomenclatures = product.additionalNomenclatures || [];
  additionalNomenclatures = additionalNomenclatures.filter(
    (course) => course.active !== false,
  );
  if (!additionalNomenclatures.length) return '';
  return `+${vm
    .$tc('course', additionalNomenclatures.length)
    .toUpperCase()} ${additionalNomenclatures
    .map((nomenclature) => nomenclature.shortName || nomenclature.name)
    .join(', ')
    .toUpperCase()} ${vm.$t('sale.free').toUpperCase()}`;
}

function lockScroll(payload) {
  if (!process.browser) return;
  const html = document.documentElement;
  if (payload) html.classList.add('overflow-y-hidden');
  else html.classList.remove('overflow-y-hidden');
}
