//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getWebinarType } from '../../pages/-webinars/-types';

export default {
  name: 'WebinarsItem',
  props: {
    item: Object,
    snippet: Boolean,
  },
  computed: {
    href() {
      return this.statusPassed && this.item.recordLink
        ? this.item.recordLink
        : this.item.pageUrl;
    },
    type() {
      return this.$t(getWebinarType(this.item.type));
    },

    startDateLocal() {
      return this.utcOffset(this.item.startDateUtc);
    },
    startDateLocalString() {
      return this.format(this.startDateLocal);
    },
    currentDateLocal() {
      return this.utcOffset(this.$dayjs.utc());
    },
    currentDateLocalString() {
      return this.format(this.currentDateLocal);
    },

    statusPassed() {
      // return this.currentDateLocal.isAfter(this.startDateLocal)
      // return !!this.item.nearestUpcomingTopic
      return !this.statusLive && !this.statusUpcoming;
    },
    statusLive() {
      // return this.currentDateLocal.isAfter(this.startDateLocal) && this.currentDateLocal.isBefore(this.startDateLocal.clone().add(2, 'hours'))
      return !!this.item.nearestLiveTopic;
    },
    statusUpcoming() {
      // return this.currentDateLocal.isBefore(this.startDateLocal)
      return !!this.item.nearestUpcomingTopic;
    },
  },
  methods: {
    format(date) {
      return this.$dayjs(date).format('DD.MM.YYYY, HH:mm');
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
    clickHandler() {
      if (this.snippet) {
        this.$gtm.push({
          event: 'autoEvent',
          eventCategory: 'webinar_click',
          eventAction: 'recommendations',
          eventLabel: this.item.name,
        });
      }

      this.$emit('click');
    },
  },
};
