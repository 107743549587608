import getCookie from '@/utils/getCookie';

export const state = () => ({
  // List page
  list: [],
  listMore: false,
  listOffset: 0,
  listLimit: 11,
  listMobileFiltersModal: false,

  // Detailed Page
  url: '/webinars',
  directions: [],
  allDirections: [],
  types: [
    'webinar',
    'marathon',
    'success_case',
    'workshop',
    'discussion',
    'demonstration',
    'masterclass',
    'immersion',
  ],

  data: {},
  direction: {},
  selectedTopic: 0,
  selectedTopicData: {},
  started: false,

  isSubscribed: false,
  token: false,
  chatUrl: '',
  videoUrl: '',

  mobileStreamView: false,
  registerDialog: false,

  eventEnded: false,

  thanksDialog: false,

  // Автовебинары
  autoWebinars: {
    needRedirect: false, // True, eсли в конфиге есть соответствующая запись
    redirectUrl: null,
    allowRedirect: false, // True, если условия для редиректа выполнены,

    activeTopicAxlWebinarId: null,
    subscriberEmail: null,
  },
});

export const getters = {
  GET_DIRECTION_BY_SLUG: (state) => (slug) => {
    return state.directions.find((i) => i.eventSlug === slug);
  },
  GET_DIRECTION_BY_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === id);
  },
  GET_DIRECTION_SLUG_FROM_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === id)?.eventSlug;
  },
  GET_DIRECTION_ID_FROM_SLUG: (state) => (eventSlug) => {
    return state.directions.find((i) => i.eventSlug === eventSlug)?.id;
  },
  /**
   * Считаем ивент завершённым
   * через 30 часов после начала последнего топика
   * https://ebaconline.atlassian.net/browse/FT-1087
   */
  GET_IS_EVENT_ENDED:
    (state) =>
    ({ event, vm, lastTopicDateUtc }) => {
      // Без vue не получится использовать dayjs
      if (!vm) return false;

      // По-умолчанию - 30 часов
      const deadlineOffset =
        vm.$nuxt.$config.DISPLAY_READONLY_WEBINAR_CHAT_AFTER + 86400000;

      // Ивент можно передать вручную.
      // Если его нет, то значение берётся из store
      if (!event) event = state.data;

      // Дату последнего топика можно передать вручную.
      // Если её нет, то значение берётся из ивента
      if (!lastTopicDateUtc) {
        const topics = event.topics;
        const lastTopic = topics[topics.length - 1];
        lastTopicDateUtc = lastTopic.topicDateUtc;
      }

      const lastTopicStart = vm.$dayjs(lastTopicDateUtc);

      const now = vm.$dayjs();
      const diffTime = lastTopicStart.diff(now);

      return diffTime * -1 > deadlineOffset;
    },
};

export const mutations = {
  SET_LIST(state, { list, offset, more }) {
    state.list = list;
    state.offset = offset;
    state.listMore = more;
  },
  INCREASE_LIST_OFFSET(state) {
    const offset = state.listOffset;
    const limit = state.listLimit;
    state.listOffset = offset + limit;
    state.listLimit = 12;
  },
  RESTORE_LIST_OFFSET_AND_LIMIT(state) {
    state.listOffset = 0;
    state.listLimit = 11;
  },
  SET_LIST_MOBILE_FILTERS_MODAL(state, payload) {
    state.listMobileFiltersModal = payload;
  },

  SET(state, payload) {
    state.data = payload;
  },
  SET_SELECTED_TOPIC(state, index) {
    state.selectedTopic = index;
  },
  SET_SELECTED_TOPIC_DATA(state, data) {
    state.selectedTopicData = data;
  },
  SET_DIRECTION(state, payload) {
    state.direction = payload;
  },
  SET_ALL_DIRECTIONS(state, payload) {
    const directions = payload.filter((i) => !!i.eventSlug);
    state.allDirections = payload;
    state.directions = directions;
  },
  SET_STARTED(state, payload) {
    state.started = payload;
  },
  SET_LIVE(state, payload) {
    const activeTopicIndex = state.data.activeTopic.num;
    if (payload) {
      state.data.topics[activeTopicIndex].status = 'live';
      state.data.activeTopic.status = 'live';
    } else {
      state.data.topics[activeTopicIndex].status = 'finished';
      state.data.activeTopic.status = 'finished';
    }
  },
  SET_CHAT_URL(state, payload) {
    state.chatUrl = payload;
  },
  SET_VIDEO_URL(state, payload) {
    state.videoUrl = payload;
  },
  SET_MOBILE_STREAM_VIEW(state, payload) {
    state.mobileStreamView = payload;
  },
  SET_REGISTER_DIALOG(state, payload) {
    state.registerDialog = payload;
  },
  SET_IS_SUBSCRIBED(state, payload) {
    state.isSubscribed = payload;
  },
  SET_EVENT_ENDED(state, payload) {
    state.eventEnded = payload;
  },
  SET_THANKS_DIALOG(state, payload) {
    state.thanksDialog = payload;
  },
  SET_AUTO_WEBINARS_NEED_REDIRECT(state, payload) {
    state.autoWebinars.needRedirect = payload;
  },
  SET_AUTO_WEBINARS_REDIRECT_URL(state, payload) {
    state.autoWebinars.redirectUrl = payload;
  },
  SET_AUTO_WEBINARS_ALLOW_REDIRECT(state, payload) {
    state.autoWebinars.allowRedirect = payload;
  },
  SET_AUTO_WEBINARS_ACTIVE_TOPIC_AXL_WEBINAR_ID(state, payload) {
    state.autoWebinars.activeTopicAxlWebinarId = payload;
  },
  SET_AUTO_WEBINARS_SUBCRIBER_EMAIL(state, payload) {
    state.autoWebinars.subscriberEmail = payload;
  },
};

export const actions = {
  async SET_LIST({ commit, state }, { filter, push = false }) {
    let { list, offset, more } = await fetchEventsList(filter, this);

    if (push) {
      list = [...state.list, ...list];
    }

    commit('SET_LIST', { list, offset, more });
  },
  async SET({ commit, state, dispatch }, { slug, config }) {
    const data = await fetchEvent(slug, this);

    if (!data) return;

    commit('SET', data);
    dispatch('SET_AUTO_WEBINARS_REDIRECT_DATA', { slug, config });
    commit('SET_DIRECTION', getDirection(data, state));
    dispatch('CHECK_STATUS', { data, vm: this });
    dispatch('SET_SELECTED_TOPIC', data.activeTopic.num);

    return true;
  },
  SET_SELECTED_TOPIC({ commit, state }, index) {
    commit('SET_SELECTED_TOPIC', index);
    commit('SET_SELECTED_TOPIC_DATA', state.data.topics[index]);
  },
  async SET_CHAT_URL({ commit, state }, { aToken }) {
    const url = await generateChatUrl(state, this, aToken);
    commit('SET_CHAT_URL', url);
    return url;
  },
  async GET_SUBSCRIPTION({ commit }, { a_token, vm }) {
    if (!a_token) return false;

    const result = await getSubscription(vm, a_token);
    commit('SET_IS_SUBSCRIBED', !!result);

    return result;
  },
  CHECK_STATUS({ commit }, { data, vm }) {
    let live = false;
    let started = false;
    let ended = false;

    // Check if started / live
    const now = vm.$dayjs.utc();
    const topicDate = vm.$dayjs(data.activeTopic.topicDateUtc);
    const diffTime = topicDate.diff(now);

    if (data.activeTopic.num > 0) {
      started = true;
    }

    if (diffTime < 600000 /* 10 минут */) {
      started = true;
      live = true;
    }

    // Check if ended
    if (diffTime < -7200000 /* 2 часа с момента начала */) {
      ended = true;
    }

    commit('SET_STARTED', started);
    commit('SET_LIVE', live);
    commit('SET_EVENT_ENDED', ended);
  },
  async GET_DIRECTIONS({ state } /*, { vm }*/) {
    if (state.directions.length) {
      // const allDirectionsOption = {
      //   name: vm.$t('direction.coursesMobile'),
      //   id: ''
      // };

      const filtered = state.directions.filter((i) => i.id && i.eventsCount);
      // return [allDirectionsOption, ...filtered]
      return filtered;
    }
    // Get directions from server ?
  },
  async GET_TYPES({ state }, { vm }) {
    if (state.types.length) {
      // const allTypesOption = {
      //   label: vm.$t('rt0822.events.filters.types.placeholder'),
      //   value: ''
      // };

      const types = state.types.map((i) => ({
        label: vm.$t(`webinar.type.${i}`),
        value: i,
      }));

      // return [allTypesOption, ...types]
      return types;
    }
  },
  SET_AUTO_WEBINARS_REDIRECT_DATA({ state, commit }, { slug, config }) {
    const autoWebinarsRedirects = config.autoWebinarsRedirects;
    if (autoWebinarsRedirects?.length) {
      const foundRedirectMap = autoWebinarsRedirects.find(
        (redirectMap) => redirectMap.fromWebinarSlug === slug,
      );

      if (foundRedirectMap) {
        commit('SET_AUTO_WEBINARS_NEED_REDIRECT', true);
        commit('SET_AUTO_WEBINARS_REDIRECT_URL', foundRedirectMap.to);
      } else {
        commit('SET_AUTO_WEBINARS_NEED_REDIRECT', false);
        commit('SET_AUTO_WEBINARS_REDIRECT_URL', null);
      }

      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', false);
    }
    if (state.data?.activeTopic?.axlWebinarId) {
      commit(
        'SET_AUTO_WEBINARS_ACTIVE_TOPIC_AXL_WEBINAR_ID',
        state.data.activeTopic.axlWebinarId,
      );
    }
  },
  SET_AUTO_WEBINARS_ALLOW_REDIRECT_BY_CONDITION({ state, commit, dispatch }) {
    if (state.data?.activeTopic?.status === 'live' && state.isSubscribed) {
      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', true);
      dispatch('ATTEMPTED_REDIRECT_TO_AUTO_WEBINAR');
    } else {
      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', false);
    }
  },
  async ATTEMPTED_REDIRECT_TO_AUTO_WEBINAR({ state, commit }) {
    if (
      state.autoWebinars.allowRedirect &&
      state.autoWebinars.needRedirect &&
      state.autoWebinars.redirectUrl
    ) {
      window.location.replace(state.autoWebinars.redirectUrl);
    } else if (
      state.autoWebinars.allowRedirect &&
      state.autoWebinars.activeTopicAxlWebinarId &&
      (this.$app.user?.email || state.autoWebinars.subscriberEmail)
    ) {
      commit('SET_LOADING_PAGE_UP', null, { root: true });
      const loginLink = await getLoginLinkAutoWebinar(this, {
        a_token: getCookie({ name: 'a_token', cookies: document.cookie }),
        email: this.$app.user?.email || state.autoWebinars.subscriberEmail,
        axlWebinarId: state.autoWebinars.activeTopicAxlWebinarId,
      });
      if (!loginLink) {
        commit('SET_LOADING_PAGE_DOWN', null, { root: true });
      } else {
        window.location.href = loginLink;
      }
    }
  },
  SET_SUBCRIBER_EMAIL_BY_FORM({ commit }, email) {
    commit('SET_AUTO_WEBINARS_SUBCRIBER_EMAIL', email);
  },
};

// Functions
async function fetchEventsList(filter, vm) {
  try {
    const response = await vm.$axios({
      method: 'get',
      url: vm?.$config?.CMS_REST_API
        ? `${vm.$config.CMS_REST_API}/public/v2/events/`
        : `https://cms.ebaconline.com.br/api/public/v2/events/`,
      params: filter,
    });

    return {
      list: response.data.data.data,
      offset: filter.offset + filter.limit,
      more: filter.offset + filter.limit < response.data.data.meta.total,
    };
  } catch (e) {
    console.error(e);
  }
}
async function fetchEvent(slug, vm) {
  try {
    const response = await vm.$axios({
      method: 'get',
      url: `${vm.$config.CMS_REST_API}/public/v2/event`,
      params: {
        slug,
      },
    });

    return response.data.data;
  } catch (e) {
    console.error('getEvent error: ', e);
    return false;
  }
}

function getDirection(event, state) {
  if (event.directionId) {
    const direction = state.allDirections.find(
      (i) => i.id === event.directionId,
    );
    return direction;
  }
}

async function generateChatUrl(state, vm, aToken) {
  try {
    const eventId = state.data.id;
    const topicId = state.selectedTopicData.id;
    const isSubscribed = state.isSubscribed;
    const topicIsLive = state.selectedTopicData.status === 'live';
    const lang = vm.$config.COUNTRY === 'mx' ? 'es' : 'pt';

    const response = await vm.$axios({
      method: 'post',
      url: `${vm.$config.CMS_REST_API}/public/getEmbyChat/`,
      data: {
        eventId,
        topicId,
        ...(topicIsLive &&
          isSubscribed &&
          aToken && {
            aToken,
          }),
        lang,
      },
    });

    return response.data.data;
  } catch (e) {
    console.error(e);
  }
}

async function getSubscription(vm, a_token) {
  try {
    const response = await vm.$axios({
      method: 'get',
      url: vm.$app.config.current.checkSubscriptionsLink,
      params: {
        webinarSlug: vm.$route.params.slug,
      },
      headers: { Authorization: `Bearer ${a_token}` },
    });

    return response.data.isMine;
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function getLoginLinkAutoWebinar(vm, { a_token, email, axlWebinarId }) {
  try {
    const {
      data: { loginLink },
    } = await vm.$axios({
      method: 'post',
      url: vm.$app.config.current.autoWebinarLoginLinkAws,
      data: {
        a_token,
        email,
        axlWebinarId,
        country: vm.$app.config.current.code,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
      },
    });
    return loginLink;
  } catch (error) {
    console.error(error);
    return null;
  }
}
