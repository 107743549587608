//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'RegistrationForm',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      console.log(54);
      // if (this.loading.form) return;
      //
      // this.loading.form = true;
      // const isValid = await this.$refs.form.validate();
      //
      // if (!isValid) {
      //   this.loading.form = false;
      //   return;
      // }
      //
      // const formData = new FormData(this.$refs.form.$el.querySelector('form'));
      // formData.append('name', this.name);
      // formData.append('phone', this.phone);
      // formData.append('email', this.email);
      //
      // try {
      //   const response = await this.$axios({
      //     method: 'post',
      //     url: 'https://gdd.ebaconline.com.br/exec',
      //     data: urlencodeFormData(formData),
      //     headers: {
      //       'Content-Type': `application/x-www-form-urlencoded`,
      //     },
      //   });
      //
      //   this.successDialog = true;
      //
      //   this.$refs.form.reset();
      //   this.name = '';
      //   this.phone = '';
      //   this.email = '';
      //
      //   this.$gtm.push({ 'event': 'autoEvent', 'eventCategory': 'lead', 'eventAction': 'index_footer' });
      // } catch (e) {
      //   console.error(e);
      // }
      //
      // this.loading.form = false;
    },
  },
};
