import { nanoid } from 'nanoid';

const defaultData = {
  title: '',
  description: '',
  action: '',
  persistent: false,
  callback: () => {},
  onClose: () => {},
};

const animationDuration = 200;

export const state = () => ({
  id: 0,
  display: false,
  closing: false,
  opening: false,
  data: defaultData,
  buzy: false,
});

export const mutations = {
  _CLEAR_DATA(state) {
    state.data = defaultData;
  },
  /** Устанавливается класс closing для модалки */
  _START_CLOSING(state) {
    state.closing = true;
  },
  /** Устанавливается класс opening для модалки */
  _START_OPENING(state, payload) {
    state.opening = true;
    state.data = payload;
    state.display = true;
  },
  /** Когда анимация появления окна закончится, убрать класс opening */
  _OPEN(state) {
    state.opening = false;
  },
  /** Когда анимация закрытия окна закончится, убрать класс closing и удалить модалку */
  _CLOSE(state) {
    state.closing = false;
    state.display = false;
    state.data = defaultData;
  },
  /** Устанавливает классы disabled и loading на кнопку в модалке */
  _SET_BUZY(state, payload) {
    state.buzy = payload;
  },
  /** id нужен для установления, была ли открыта новая модалка поверх другой */
  _SET_ID(state, payload) {
    state.id = payload || nanoid();
  },
};

export const actions = {
  // Внутренние экшены. Вызываются только изнутри стора
  async _CLOSE_WITH_ANIMATION({ commit }) {
    commit('_START_CLOSING');
    await wait(animationDuration);
    commit('_CLOSE');
    commit('_SET_ID', -1);
  },
  async _OPEN_WITH_ANIMATION({ commit }, payload) {
    commit('_START_OPENING', payload);
    await wait(animationDuration);
    commit('_OPEN');
  },

  // Основные экшены. Вызываются извне
  async OPEN({ state, commit, dispatch }, payload) {
    if (state.opening || state.closing) return;
    commit('_SET_ID');

    // Если окно уже открыто - нужно его закрыть перед открытием нового
    if (state.display) {
      await dispatch('_CLOSE_WITH_ANIMATION');
    }

    await dispatch('_OPEN_WITH_ANIMATION', payload);
  },
  async CLOSE({ state, dispatch, commit }) {
    if (state.opening || state.closing) return;
    const id = state.id;

    // Если у функции коллбека есть async, то нужно подождать её выполнения
    // на это время у кнопки в модальном окне появится стейт загрузки
    // и она будет задизейблена
    commit('_SET_BUZY', true);
    try {
      const callback = state.data.callback;
      const onClose = state.data.onClose;
      await callback?.();
      onClose?.();
    } catch (e) {
      console.error(e);
    }
    commit('_SET_BUZY', false);

    // Если id изменился - значит в коллбеке открылся новый modalNotify
    // Это значит, что старого уже нет и закрывать его не нужно
    if (id !== state.id) return;
    await dispatch('_CLOSE_WITH_ANIMATION');
  },
  /** Закрыть модалку без вызова callback */
  async CLOSE_FORCE({ state, dispatch }) {
    if (state.opening || state.closing) return;

    try {
      const onClose = state.data.onClose;
      onClose?.();
    } catch (e) {
      console.error(e);
    }

    await dispatch('_CLOSE_WITH_ANIMATION');
  },
};

async function wait(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
}
