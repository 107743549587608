//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import HintEmail from '../../common/hintEmail';
import Phone from '../../common/phone';
import addAnyFormFields from '../../../utils/addAnyFormFields';
import urlencodeFormData from '../../../utils/urlencodeFormData';
import SuccessImg from '~/assets/svg/success-img.svg?inline';
import PrivacyPolicy from '../../common/privacyPolicy';

export default {
  name: 'MainFormSection',
  components: {
    ValidationObserver,
    ValidationProvider,
    HintEmail,
    Phone,
    SuccessImg,
    PrivacyPolicy,
  },
  data() {
    return {
      name: '',
      phone: '',
      email: null,
      acceptedRules: true,

      loading: {
        form: false,
      },

      successDialog: false,
      showBigDialog: false,

      attach: null,
    };
  },
  watch: {
    successDialog() {
      setTimeout(() => {
        this.successDialog = false;
      }, Number(this.$nuxt.$config.CLOSE_SUCCESS_DIALOG_TIME));
    },
  },
  async mounted() {
    setTimeout(
      async () => await addAnyFormFields.call(this, { ref: this.$refs.form }),
      0,
    );

    this.attach = this.$refs['app'].$el;
  },
  methods: {
    async onSubmit() {
      if (this.loading.form) return;

      this.loading.form = true;
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.loading.form = false;
        return;
      }

      const formData = new FormData(this.$refs.form.$el.querySelector('form'));
      formData.append('name', this.name);
      formData.append('phone', this.phone);
      formData.append('email', this.email);
      formData.append('checkbox', this.acceptedRules);

      try {
        await this.$axios({
          method: 'post',
          url: this.$app.config.current.feedbackUrl,
          data: urlencodeFormData(formData),
          headers: {
            'Content-Type': this.$app.config.current.feedbackContentType,
          },
        });

        this.successDialog = true;

        this.$refs.form.reset();
        this.name = '';
        this.phone = '';
        this.email = '';

        this.$gtm.push({
          event: 'autoEvent',
          eventCategory: 'lead',
          eventAction: 'index_footer',
        });
      } catch (e) {
        console.error(e);
      }

      this.loading.form = false;
    },
    onResize() {
      this.showBigDialog = window.innerHeight >= 490;
    },
  },
};
