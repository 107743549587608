import message from '../i18n/pt-br';
import { vwoCodeBR } from './const';

// Бразилия
export default {
  // Плашка распродажи
  saleBanner: {
    endDate: '2023-10-07T02:59:00Z', // пример 2022-08-27T02:59:00Z - до 26 августа включительно
    eternal: true, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '110923', // название распродажи, формат ddmmyy
    link: 'https://ebaconline.com.br/sale/invista-em-voce', // ссылка на распродажу
  },

  // Slug распродажи, которую нужно отобразить в новом дизайне
  saleNewDesignSlug: [
    'futuro-em-dobro',
    'semana-do-consumidor',
    'fabrica-de-carreiras',
    'ano-novo',
    'natal-wheel',
    'natal',
    'invista',
    'black-friday-2x1',
    'cyber-monday',
    'black-friday',
    'futuro-garantido',
    'curso-de-produtividade',
    'garantia-de-futuro',
    'referral',
    'test-sale-1',
    // 'test-sale-2',
    'test-sale-3',
  ],

  saleReferralSlug: ['referral'],

  // Поведение корзины на странице распродажи
  // Ключ - slug, value - опция
  // 1 - дефолт
  // 2 - дефолт + при добавлении в корзину, popup корзины сразу же будет появляться
  // 3 - как таковой корзины нет, при добавлении в корзину будет появляться popup корзины,
  // но в popup`e будут только форма, списка продуктов не будет. После закрытия popup`а,
  // товар будет удаляться из корзины. Плавающая иконка корзины пропадает
  saleBasketType: {
    'ano-novo': 2,
    'natal-wheel': 2,
    natal: 1,
    invista: 2,
    'cyber-monday': 1,
    'garantia-de-futuro': 2,
    'black-friday': 1,
    'test-sale-1': 1,
    'test-sale-2': 2,
    'test-sale-3': 3,
    'test-sale-4': 1,
    'test-sale-5': 2,
    'test-sale-6': 3,
  },

  // Images берутся из assets/images/saleNew/unique/<file>
  saleNewImages: [
    {
      slug: 'analista-de-dados',
      name: 'analista-de-dados.jpg',
    },
    {
      slug: 'cientista-de-dados',
      name: 'cientista-de-dados.jpg',
    },
    {
      slug: 'designer-de-interiores',
      name: 'designer-de-interiores.jpg',
    },
    {
      slug: 'designer-grafico',
      name: 'designer-grafico.jpg',
    },
    {
      slug: 'full-stack-java',
      name: 'full-stack-java.jpg',
    },
    {
      slug: 'full-stack-python',
      name: 'full-stack-python.jpg',
    },
    {
      slug: 'it-start',
      name: 'it-start.jpg',
    },
    {
      slug: 'mkt-start',
      name: 'mkt-start.jpg',
    },
    {
      slug: 'uxui-designer',
      name: 'uxui-designer.jpg',
    },

    {
      slug: 'copywriting',
      name: 'copywriting.jpg',
    },
    {
      slug: 'design-grafico',
      name: 'design-grafico.jpg',
    },
    {
      slug: 'fashion-business',
      name: 'fashion-business.jpg',
    },
    {
      slug: 'fashion-design',
      name: 'fashion-design.jpg',
    },
    {
      slug: 'fotografia',
      name: 'fotografia.jpg',
    },
    {
      slug: 'marketing-digital',
      name: 'marketing-digital.jpg',
    },
    {
      slug: 'personal-stylist',
      name: 'personal-stylist.jpg',
    },
    {
      slug: 'power-bi',
      name: 'power-bi.jpg',
    },
    {
      slug: 'roteiro',
      name: 'roteiro.jpg',
    },
  ],

  // Slug распродажи, на котором будет отображено колесо фортуны
  fortuneWheelSlug: [
    'natal-wheel',
    'test-sale-2',
    'fortune-wheel',
    'volta-as-aulas',
  ],

  // Редиректы на платформу автовебинаров
  autoWebinarsRedirects: [
    {
      fromWebinarSlug: 'test-5',
      to: 'https://s14795.accelsite.io/w/1546007',
    },
  ],

  // Локализация
  localeName: 'pt-br',
  utcOffset: -3,
  message,
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'BRL',
      currencyDisplay: 'symbol',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'BRL',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
  },
  defaultPhoneMask: 'bm',

  newDomainName: '', // домен, который будет заменять домен у некоторых ссылок, приходящих из cms. 1g5jk12

  // LMS
  lmsUrl: 'https://lms.ebaconline.com.br/',
  lmsLoginUrl: 'https://lms.ebaconline.com.br/login/',

  // Webinars
  withWebinars: true,
  webinarsUrl: '/webinars',
  // Blog
  withBlog: true,
  tildaBlog: false,
  blogUrl: 'https://ebaconline.com.br/blog',
  getBlogsUrl: '/public/getBlogPosts',
  blogLinks: [
    {
      href: '/blog#!/tab/238198275-2',
      key: 'template.design',
    },
    {
      href: '/blog#!/tab/238198275-3',
      key: 'template.audioVisual',
    },
    {
      href: '/blog#!/tab/238198275-4',
      key: 'template.marketing',
    },
    {
      href: '/blog#!/tab/238198275-5',
      key: 'template.scheduleAndDate',
    },
    {
      href: '/blog#!/tab/238198275-6',
      key: 'template.games',
    },
  ],

  withNewCoursesSection: true,

  feedbackUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/register',
  feedbackContentType: 'application/x-www-form-urlencoded',
  leadFormUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/course',
  saleUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/course',
  promocodeUrl: 'https://cms.ebaconline.com.br/api/shopping-cart',
  wordPressPosts: 'https://ebaconline.com.br/blog/wp-json/wp/v2/posts',
  webinarsCreateLeadUrl: 'https://gdd.ebaconline.com.br/exec',
  webinarsCreateLeadUrlAws:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/br/subscribe/webinar',
  webinarsCreateLeadContentType: 'application/x-www-form-urlencoded',
  getPaymentLink: 'https://gdd.ebaconline.com.br/api/get_payment_link',
  getDetailWithPaymentLink: 'https://billing.ebac.app/offer/detail-with-links',
  webinarVisitorEventsUrl:
    'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',
  showcaseApiUrl: 'https://cms.ebaconline.com.br/api/public/v2/showcase',
  checkSubscriptionsLink:
    'https://api.lms.ebaconline.com.br/subscriptions/is-mine',

  // Source id
  sourceId: {
    index: 8,
    sale: 132,
    saleBasket: 131,
  },

  // Documents
  documents: {
    serviceAgreementAndPrivacyPolicy:
      'https://ebaconline.com.br/upload/cms/service_agreement_and_privacy_policy.pdf',
    linkToMx: {
      attrs: { title: 'Ebac.mx' },
      link: 'https://ebac.mx/cursos',
    },
  },
  footerDocuments: ['serviceAgreementAndPrivacyPolicy', 'linkToMx'],
  formDocument: 'serviceAgreementAndPrivacyPolicy', // documents.serviceAgreementAndPrivacyPolicy
  formWithCheckbox: true,

  // Social
  phone: '+55 (11) 3030-3200',
  phoneLink: 'tel:+551130303200',
  whatsApp: '+55 (11) 4200-2991',
  whatsAppLink: 'https://wa.me/551142002991',
  email: 'suporte@ebaconline.com.br',
  emailLink: 'mailto:suporte@ebaconline.com.br',
  instagramLink: 'https://www.instagram.com/e.b.a.c/',
  facebookLink: 'https://www.facebook.com/escolabritanicadeartescriativas/',
  youtubeLink: 'https://www.youtube.com/EBACoficial',
  linkedInLink:
    'https://linkedin.com/school/ebac-escola-britanica-artes-criativas',
  tiktok: '',

  // Vacancies
  vacancies: {
    locations: [
      {
        slug: 'sao-paulo',
        value: 'São Paulo',
        label: 'São Paulo',
      },
      {
        slug: 'remote',
        value: 'Remote',
        label: 'Remote',
      },
    ],
  },
  externalVacanciesLink: 'https://ebac.gupy.io/',
  ourStudentsLink: 'https://ebaconline.com.br/depoimentos',
  massMediaLink: 'https://ebaconline.com.br/midia',
  studentReviewsLink: 'https://ebaconline.com.br/depoimentos',
  EBACInMedia: 'https://ebaconline.com.br/midia',

  //b2b landing link
  b2bLink: 'https://ebaconline.com.br/sobre/para-empresas',
  b2bLinks: {
    corporateEducation: {
      url: 'https://ebaconline.com.br/para-empresas/corporate-education',
    },
    challenges: { url: 'https://ebaconline.com.br/para-empresas/challenges' },
  },

  // Global head
  head: {
    lang: 'pt',
    meta: [],
    link: [
      // Преконнект сервиса a/b тестов
      {
        rel: 'preconnect',
        href: 'https://dev.visualwebsiteoptimizer.com',
      },
    ],
    script: [
      // {
      //   src: 'https://cdn.popupsmart.com/bundle.js',
      //   body: true,
      //   async: true,
      //   defer: true,
      //   'data-id': 11931
      // }

      {
        src: 'https://cdn.jsdelivr.net/npm/vue@2.6.14',
        body: true,
        defer: true,
      },
      {
        src: 'https://embedded.ebaconline.com.br/br/cookie_consent/init.js',
        body: true,
        defer: true,
      },
      {
        src: 'https://embedded.ebaconline.com.br/br/cookie_consent/cookie-consent.min.js',
        body: true,
        defer: true,
      },

      // Подключение сервиса a/b тестов
      {
        type: 'text/javascript',
        id: 'vwoCode',
        innerHTML: vwoCodeBR,
      },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },

  bodyScripts: [],

  // career-center-students script
  careerCenterStudentsScripts: [
    // {
    //   innerHTML: `
    //     var yWidgetSettings = {
    //       buttonColor : '#1C84C6',
    //       buttonPosition : 'bottom right',
    //       buttonAutoShow : true,
    //       buttonText : 'Online booking',
    //       formPosition : 'right'
    //     };
    //   `,
    //   type: 'text/javascript',
    // },
  ],

  gtm: {
    debug: false,
    id: 'GTM-MD8LLQM',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
  },

  //hreflang linking SEO
  hreflang: 'pt-BR',
  href: 'https://ebaconline.com.br',
  code: 'br',

  // Список id номенклатур, для которых выводится категория Pós-Graduação
  postGraduateIds: [199, 200],

  /**
   * Список распродаж, для которых автоматически активируется промокод
   * Если readonly: true - активированный купон нельзя стереть
   * или изменить
   *
   * Пример:
   * {
   *  saleSlug: 'junho-da-virada',
   *  code: '230613_dvefp4cf',
   *  readonly: false,
   * },
   * */

  autoFillPromoCodeForSales: [],

  autoWebinarLoginLinkAws:
    'https://external-api.ebac.app/axl-autowebinar-subscription-processing',
};
