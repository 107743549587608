import br from './br';
import mx from './mx';
import ar from './ar';
import cl from './cl';
import pe from './pe';
import co from './co';

const CONFIGS = {
  br,
  mx,
  ar,
  cl,
  pe,
  co,
};

export default (CURRENT_COUNTRY) => ({
  common: {
    currentCountry: CURRENT_COUNTRY,
    ...Object.values(CONFIGS).reduce(
      (acc, value) => {
        if (!acc.locales.includes(value.localeName)) {
          acc.locales.push(value.localeName);
        }

        if (
          !Object.prototype.hasOwnProperty.call(
            acc.numberFormat,
            value.localeName,
          )
        ) {
          acc.numberFormat[value.localeName] = value.numberFormat;
        }

        if (
          !Object.prototype.hasOwnProperty.call(acc.messages, value.localeName)
        ) {
          acc.messages[value.localeName] = value.message;
        }
        if (value.code && !acc.codes.includes(value.code)) {
          acc.codes.push(value.code);
        }
        return acc;
      },
      {
        locales: [],
        numberFormat: {},
        messages: {},
        codes: [],
      },
    ),
  },
  current: CONFIGS[CURRENT_COUNTRY],
  ...CONFIGS,
});
