var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VApp',{ref:"app",staticClass:"main-form-section"},[_c('section',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"section"},[_c('div',{staticClass:"m-container"},[_c('div',{staticClass:"main-form-title"},[_c('h2',{staticClass:"h2"},[_vm._v(_vm._s(_vm.$t('index.main-form-section.h2')))])]),_vm._v(" "),_c('p',{staticClass:"main-form-description"},[_vm._v("\n        "+_vm._s(_vm.$t('index.main-form-section.description'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"main-form-controls"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"main-form-name-phone"},[_c('ValidationProvider',{attrs:{"mode":"custom","name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VTextField',{staticClass:"main-form-name-phone__name",attrs:{"label":_vm.$t('index.main-form-section.name.label'),"solo":"","error-messages":errors.length
                      ? _vm.$t('index.main-form-section.name.error')
                      : ''},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_vm._v(" "),_c('Phone',{staticClass:"main-form-name-phone__phone",attrs:{"name":"phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"mode":"custom","name":"email","rules":"required|email|emailInBlackList"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('HintEmail',{attrs:{"label":_vm.$t('index.main-form-section.email.label'),"error-messages":errors.length
                    ? _vm.$t('index.main-form-section.email.error')
                    : ''},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('PrivacyPolicy',{model:{value:(_vm.acceptedRules),callback:function ($$v) {_vm.acceptedRules=$$v},expression:"acceptedRules"}}),_vm._v(" "),_c('div',{staticClass:"main-form-btn"},[_c('VBtn',{attrs:{"color":"primary","rounded":"","depressed":"","x-large":"","ripple":false,"type":"submit","loading":_vm.loading.form}},[_vm._v("\n                "+_vm._s(_vm.$t('index.main-form-section.btn'))+"\n              ")])],1),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"source_id","tabindex":"-1"},domProps:{"value":_vm.$app.config.current.sourceId.index}}),_vm._v(" "),(_vm.$app.cookies.lastScheduleEvent)?_c('input',{attrs:{"type":"hidden","name":"last_schedule_event","tabindex":"-1"},domProps:{"value":_vm.$app.cookies.lastScheduleEvent}}):_vm._e()],1)]}}])})],1)]),_vm._v(" "),_c('VDialog',{attrs:{"max-width":"490","attach":_vm.attach},model:{value:(_vm.successDialog),callback:function ($$v) {_vm.successDialog=$$v},expression:"successDialog"}},[_c('VCard',{staticClass:"success-dialog",class:{
          'success-dialog_big': _vm.showBigDialog,
        }},[_c('div',{staticClass:"close-dialog",on:{"click":function($event){_vm.successDialog = false}}}),_vm._v(" "),_c('div',{staticClass:"success-dialog__img"},[_c('SuccessImg')],1),_vm._v(" "),_c('div',{staticClass:"h3 success-dialog__title"},[_vm._v("\n          "+_vm._s(_vm.$t('index.success-dialog.title'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"success-dialog__description"},[_vm._v("\n          "+_vm._s(_vm.$t('index.success-dialog.description'))+"\n        ")]),_vm._v(" "),_c('VBtn',{staticClass:"success-dialog__btn",attrs:{"color":"primary","rounded":"","depressed":"","x-large":"","ripple":false},on:{"click":function($event){_vm.successDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('index.success-dialog.btn'))+"\n        ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }