import getCookie from '../utils/getCookie';

// TODO: user auth

export const state = () => ({
  tags: [],
  accessToken: '',
  tagsLoaded: false,
});

export const actions = {
  async getTags({ commit, state }) {
    if (state.tagsLoaded) return state.tags;
    if (this.$app.user) {
      try {
        const response = await this.$axios({
          method: 'get',
          url: `${this.$config.LMS_REST_API}/users/self/tags`,
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
          },
        });

        commit('setTags', response?.data || []);

        return state.tags;
      } catch (error) {
        console.error(error);

        return state.tags;
      }
    } else {
      commit('setTags', []);

      return state.tags;
    }
  },
  logout({ commit }) {
    commit('removeAccessTokenCookie');
    commit('removeAccessToken');
  },
};

export const mutations = {
  setTags(state, tags) {
    state.tags = tags;
    state.tagsLoaded = true;
  },
  setAccessToken(state, cookies) {
    const _cookies = process.browser ? document.cookie : cookies;
    const accessToken = getCookie({ name: 'a_token', cookies: _cookies });
    state.accessToken = accessToken;
  },
  removeAccessTokenCookie() {
    document.cookie =
      'a_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  },
  removeAccessToken(state) {
    state.accessToken = '';
  },
};
