import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';

export default ({ $config }) => {
  const ignoreErrors = [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',

    // Все сетевые ошибки, которые можно игнорировать
    'Network request failed',
    'Failed to fetch',
    'NetworkError',
    'Network Error',
    'Request aborted',
    'timeout of 30000ms exceeded',
    'Load failed', //https://sentry.io/answers/load-failed-javascript/

    // Баг во vuetify, который скорее всего уже не исправят. Появляется при определенных условиях в момент
    // скролла во VDialog
    't.hasAttribute is not a function',

    // Баг возникает только на спам ip адресах
    'Unexpected token .',

    // Воспроизводится в iOS Mobile Safari UI/WKWebView (instagram, facebook)
    "Can't find variable: _AutofillCallbackHandler",

    // Воспроизводится в довольно специфичных браузерах: Bytespider (большая часть), Chrome mobile (это исключение), Samsung Internet, Chrome (опять же, хром исключение), Facebook, Chrome Webview, Headless Chrome. Судя по реплаям на работе приложения это не сказывается
    "Non-Error promise rejection captured with value: Cannot set property 'vt' of undefined",
    "Non-Error promise rejection captured with value: Cannot read property 'setPageIdValue' of undefined",

    // Воспроизводится в WebView, на работу не влияет
    "Cannot read properties of undefined (reading 'domInteractive')",
  ];

  if ($config.COUNTRY === 'mx') {
    ignoreErrors.push(
      // Так и не решенная маркетингом проблема с пушем событий в гтм на Мексике
      "undefined is not an object (evaluating 'this.$gtm.push')",
      "Cannot read properties of undefined (reading 'push')",
    );
  }

  Sentry.init({
    Vue,
    enabled: $config.sentryEnable,
    debug: $config.sentryDebug,
    dsn: $config.sentryDSN,
    environment: $config.sentryEnvironment,
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors,
  });
};
