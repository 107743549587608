export const WebinarTypes = {
  discussion: 'discussion',
  success_case: 'success_case',
  webinar: 'webinar',
  workshop: 'workshop',
  marathon: 'marathon',
  demonstration: 'demonstration',
  masterclass: 'masterclass',
  immersion: 'immersion',

  default: 'event',
};

export const getWebinarType = (type) => {
  return `webinar.type.${WebinarTypes[type] || WebinarTypes.default}`;
};
